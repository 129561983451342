import {getFormatName, getFormatSource} from "../operations/formats";

export function isChar(char) {
	const code = char.charCodeAt(0);
	return code <= 122 ? code >= 65 || code === 35 || code === 36 : code > 191;
}

export function getLastCharIndex(str, includeNumbers){
	let lastChar = str.length;
	for(let i = lastChar - 1; i >= 0; i--){
		if(!isChar(str[i])){
			if(includeNumbers && isNumber(str[i]))
				continue;
			return lastChar;
		}
		else
			lastChar = i;
	}
}

function isNumber(char){
	var code = char.charCodeAt(0);
	return code>=48 && code<=57;
}

function getWord(formula, i, passive){
	var max = formula.length;
	var sheet = passive;
	var quotes = false;

	for(var j=i; j<max; j++){
		var key =  formula[j];
		if (key === "'"){
			quotes = !quotes;
			continue;
		}

		if (!quotes){
			if (key == "!"){
				if(formula[i] == "#") //error
					continue;
				else{
					//multi-sheet math
					sheet = formula.substr(i, j-i);
					if (sheet[0] === "'")
						sheet = sheet.substr(1, sheet.length-2);
					i=j+1;
				}
			}
			else if (!isChar(key) && !isNumber(key)){
				return [formula.substr(i, j-i), j, i, sheet];
			}
		}
	}

	return [formula.substr(i), j, i, sheet];
}

var operand = /^[A-Z$]+[0-9]+$/;
function isPosition(text){
	return operand.test(text);
}

export function position(word){
	var row = 0, sum = 0, x = 1, mode = 1, flags = 0;  
	var chars = false;

	for (var j =word.length-1; j>=0; j--){
		var key = word[j].charCodeAt(0);
		if (key === 36) {
			flags = flags+mode;
			continue;
		}

		if (key < 58){
			//numeric
			sum += (key - 48)*x;
			x *= 10;
		} else {
			if (!chars){
				x = 1; row = sum; sum = 0; chars = true; mode += 1;
			}
			//alpha
			sum += (key - 64)*x;
			x*=26;
		}
	}

	/*
		$X$Y => flags = 3
		$XY  => flags = 2
		X$Y  => flags = 1
		XY   => flags = 0
	*/
	return [row, sum, flags];
}

export function split(formula, view, crosssheet){
	const activeSheet = view.getActiveSheet();
	var lines = [];
	var index = 0;
	var quotes = false, ph = false;
	for (var i = formula[i] === "=" ? 1 : 0; i < formula.length; i++){
		var key = formula[i];
		if (key == "\""){
			quotes = !quotes;
		} else if (!quotes){
			if (key == "{" && formula[i+1] == "{"){
				ph = true;
			} else if (key == "}" && formula[i+1] == "}"){
				ph = false;
			} else if (!ph){
				const escaped = key === "'";
				if (escaped || isChar(key)){
					let [word, end, ,sheet] = getWord(formula, i, "");
					let next = end - 1;
					let value;

					if(formula[next+1] !== "("){
						if(isPosition(word)){
							if(!sheet || sheet == activeSheet || crosssheet){
								value = sheet ? [sheet, word, escaped] : word;
								pushLine(lines, formula, i, index, value);
								index = next+1;
							}
						}
						else{
							if(crosssheet){
								value = sheet ? [sheet, word, escaped] : word;
								pushLine(lines, formula, i, index, value);
								index = next+1;
							}
							else{
								let range;

								if(sheet)
									range = view.ranges.getCode(word, sheet);
								else{
									//global range
									range = view.ranges.getCode(word, true);

									//active sheet range
									if(!range)
										range = view.ranges.getCode(word);
								}

								if(range){
									const parts = range.split("!");
									if(parts[0] == activeSheet){
										value = [sheet, word, escaped, parts[1]];
										pushLine(lines, formula, i, index, value);
										index = next+1;
									}
								}
							}
						}
					}
					i = next;
				}
			}
		}
	}

	if (index!=formula.length)
		lines.push(formula.substr(index));
	return lines;
}

function pushLine(lines, formula, i, index, value){
	if (i!==0)
		lines.push(formula.substr(index, i-index));
	lines.push(value);
}

export function getMathFormat(view, value){
	let level = 0;
	let blockLevel;

	const parts = split(value, view);

	for(let i = 0; i < parts.length; i++){
		// parts[i] can contain numbers, math operators, formulas, etc.
		if(i%2 === 0){
			// check formulas and round brackets which are not in quotes
			const methods = parts[i].match(/(([a-zA-Z]*\()|\))(?=(?:[^"]|"[^"]*")*$)/g);
			if(methods)
				methods.forEach(part => {
					if(part.indexOf("(") > -1){
						level++;
						// some methods block format addition (like count,odd,etc.)
						const safeMethods = /^(SUM|AVERAGE|MAX|MIN|INT|ROUND|ROUNDDOWN|ROUNDUP|TRUNC)?\($/gi;
						if(!blockLevel && !safeMethods.test(part))
							blockLevel = level;
					}
					else if(--level < blockLevel)
						blockLevel = null;
				});
		}
		// parts[i] can be named range, cell from the active / non-active sheet
		else if(!blockLevel){
			if(webix.isArray(parts[i])){
				if(/^[A-Za-z]+$/.test(parts[i][1]))
					parts[i] = parts[i][3].split(":")[0];
				else if(parts[i][0] == view.getActiveSheet())
					parts[i] = parts[i][1];
				else
					continue; // ignore non-active sheets
			}

			// if range - check first cell position
			const cell = position(parts[i]);
			const name = getFormatName(view, cell[0], cell[1]);

			// first cell with number format
			if(name)
				return {fmt: getFormatSource(name)};
		}
	}
}