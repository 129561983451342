import * as cop from "./operations/column_operations";
import {getConditionCss} from "./operations/conditional_formats";
import * as nms from "./helpers/column_names";
import * as fmt from "./operations/formats";

export function init(view, extra){
	view.attachEvent("onComponentInit", () => ready(view));

	var datatable = {
		view:"datatable", id:"cells", css:"webix_ssheet_table webix_data_border wss_"+view.$index,
		headerRowHeight: webix.skin.$name == "contrast" || webix.skin.$name == "flat" ? 24 : 20,
		spans: true,
		leftSplit: 1,
		areaselect:true,
		minColumnWidth:38,
		editable:true,
		editaction:(extra.liveEditor ? "custom" : "dblclick"),
		minRowHeight:1,
		navigation:true
	};

	if (extra)
		datatable = webix.extend(datatable, extra, true);

	return datatable;
}

function ready(view){
	var grid = view._table;

	var showCommentHandler = webix.event(document.body, "mousemove", function(){
		var thread;
		return function(e) {
			clearTimeout(thread);
			thread = webix.delay(function(e){
				if(view.comments._activeComment.editStatus)
					return;

				var cell = grid.locate(e);
				var visible = view.comments.commentsView && view.comments.commentsView.isVisible();

				if(cell && view.comments.get(cell.row, cell.column)){
					var activeCell = view.comments._activeComment.cell;
					if((activeCell && (activeCell.row != cell.row || activeCell.column != cell.column)) || !visible)
						view.callEvent("onCommand",[{id: "add-comment", cell, viewonly: true }]);
				}
				else if(visible && !view.comments.commentsView.$view.contains(e.target))
					view.callEvent("onCommentHide", []);
			}, true, [e], 250);
		};
	}());

	//in grid math
	if (view.config.math){
		grid.config.editMath = true;
	}

	//saving value after edit
	grid.attachEvent("onBeforeEditStop", (st, ed) => {
		grid.$anyKey = false;
		//ignore empty cells
		if (st.old === webix.undefined && st.value === "") return;

		if (st.value != st.old){
			view.setCellValue(ed.row, ed.column, st.value);
			grid.refresh();
		}
		st.value = st.old;
	});

	grid.attachEvent("onEnter", function(ev){
		if (grid.getEditor()){
			webix.delay(function(){
				grid.moveSelection("down");
			});
		}
		webix.html.preventEvent(ev);
	});

	//prevent editing of locked cells
	view.attachEvent("onBeforeEditStart", (row, column) => !view.isCellLocked(row, column));
	grid.attachEvent("onAfterEditStart", ({row, column}) => {
		let v = view.getCellValue(row, column);

		if(grid.$anyKey){
			grid.$anyKey = false;
			v = "";
		}

		grid.getEditor().setValue(v);
	});
	grid.attachEvent("onBeforeEditStart", (editor) => 
		view.callEvent("onBeforeEditStart", [editor.row, editor.column]) );

	//column drag event checking
	let dragStartColumn;
	let dragEndColumn;

	//column and row selection
	grid.attachEvent("onBeforeSelect", (id) => id.column != "rowId" );
	grid.attachEvent("onBeforeBlockSelect", (start, end, finalStep) => {
		cop.highlightColRow(
			finalStep ? start : webix.copy(start),
			finalStep ? end : webix.copy(end),
			view
		);
	});

	function highlightHeaders(){
		let ids = grid.getSelectedId(true);
		if(ids.length){
			let start = ids[0];
			let end = ids[ids.length-1]?ids[ids.length-1]:ids[0];
			cop.highlightColRow(start, end, view);
		}
		else {
			let data = {row:0, column:0};
			cop.highlightColRow(data, data, view);
		}
	}

	grid.attachEvent("onSelectChange", () => highlightHeaders());
	grid.attachEvent("onAfterColumnShow", () => {
		webix.delay(() => highlightHeaders());
	});
	grid.attachEvent("onColumnResize", () => {
		webix.delay(() => highlightHeaders());
	});

	webix.event(grid.$view.firstChild, "mousedown", e => {
		const resizeColumn = grid.config.resizeColumn;
		const col = grid.locate(e);

		if(col && col.cind){
			if(resizeColumn){
				const dx = grid.getColumnConfig(col.column).width;
				const pos = webix.html.posRelative(e);
				if(pos.x < resizeColumn.size || dx-pos.x < resizeColumn.size+1)
					return;
			}
			dragStartColumn = col.cind;
		}
	});

	webix.event(grid.$view.firstChild, "mousemove", e => {
		if(dragStartColumn){
			const col = grid.locate(e);

			if(col){
				dragEndColumn = col.cind;
				cop.highlightColRow(
					{column: grid.columnId(dragStartColumn)},
					{column: grid.columnId(dragEndColumn||1)},
					view
				);
			}
		}
	});

	const selectColumnHandler = webix.event(document.body, "mouseup", () => {
		if(dragStartColumn){
			if(dragEndColumn != null){
				cop.selectColumn(
					grid.columnId(dragStartColumn),
					view,
					grid.columnId(dragEndColumn||1)
				);
				dragEndColumn = null;
			}
			dragStartColumn = null;
		}
	});

	grid.attachEvent("onItemDblClick", (id) => {
		if (id.column === "rowId")
			cop.adjustRow(id.row, view);
	});

	let lastHeaderClick = 0;
	grid.attachEvent("onHeaderClick", (id, e) => {
		if(id.column == "rowId"){
			cop.selectAll(view);
			return;
		}
		let headerClick = new Date();
		let dblClick = (headerClick - lastHeaderClick <= 300);

		if (dblClick) {
			cop.adjustColumn(id.column, view);
		} else {
			lastHeaderClick = headerClick;
			if (e.shiftKey) {
				cop.selectColumns(id.column, view);
			} else {
				cop.selectColumn(id.column, view);
			}
		}
	});

	//select rows by shift click
	var lastClickedRow = null;
	grid.attachEvent("onItemClick", (cell, e) => {
		if (cell.column === "rowId") {
			if (!e.shiftKey)
				cop.selectRow(cell.row, view);
			else {
				if (!lastClickedRow)
					cop.selectRow(cell.row, view);
				else
					cop.selectRow(lastClickedRow, view, cell.row);
			}
			lastClickedRow = cell.row;
		}
	});

	//reset API
	view.attachEvent("onReset", () => reset(view));

	view.attachEvent("onBeforeSheetShow", () => grid.editStop());

	grid.attachEvent("onBlur", function(){
		//after focus moved out, check and if it is somewhere
		//on the spreadsheet controls them move focus back to datatable
		webix.delay(function(){
			var target = document.activeElement;

			if (target && target.tagName == "INPUT") return;

			var focus = webix.UIManager.getFocus();
			var need_focus = focus &&
				focus != grid &&
				focus.getTopParentView &&
				focus.getTopParentView() === view;

			if (need_focus)
				webix.UIManager.setFocus(grid);

		}, this, [], 100);
	});

	setDefaultCss(view);

	view.attachEvent("onDestruct", () => {
		webix.eventRemove(showCommentHandler);
		webix.eventRemove(selectColumnHandler);
	});
}

export function cell_template(view, obj, common, _v, column, valueOnly){
	const col = column.id;
	const row = obj.id;

	if (!view._mPage) return "";
	const cell = view._mPage.getCell(row-1, col-1);

	let value = cell ? cell.value : "";
	const format = cell ? cell.format : "";

	const math = view.getCellValue(row, col);
	if(math != value){
		const locale = webix.i18n.spreadsheet.table;
		if(value == "#REF!")
			value = locale["math-ref-error"];
		else if(value == "#ERROR")
			value = locale["math-error"];
	}

	let css = "";
	const text = (!value && value !== 0) || isNaN(value);
	const invalidDate = fmt.checkFormat(format, "date") && fmt.isDateInvalid(value);

	let extra = "";
	if (!valueOnly){
		extra += view.comments.get(row, col) ? "<div class='ssheet_commented_sign'></div>" : "";
		extra += view.getCellFilter(row, col) ? "<div class='ssheet_filter_sign'></div>" : "";
	}

	//text can be formatted only in case of string number format
	if(!invalidDate && (!text || fmt.checkFormat(format, "string"))){
		if (format){
			const cell = { css:"" };
			const helper = fmt.getFormat(format);
			if (helper){
				let newValue = helper(value, cell);

				if(newValue != webix.i18n.spreadsheet.table["format-error"]){
					if (cell.css)
						css = `class="${cell.css}"`;
					value = newValue;
				}
				else if(!text)
					value = value * 1;
			}
		}
		else
			value = value * 1;
	}

	//print only cell values (td with position:relative ignores borders while printing)
	if(view._print && !(value || value === 0))
		return "";

	if(webix.isUndefined(value) || value === null)
		value = "";

	return `<div ${css}>${value}</div>${view._print ? "" : extra}`;
}

export function reset(view){
	const grid = view.$$("cells");
	grid.clearAll();

	const columns = view.config.columnCount;
	const rows = view.config.rowCount;

	const cols = [{
		id:"rowId", header:"", width:40,
		css:"sheet_column_0",
		template:(el) => {
			return el.id;
		}
	}];

	for (let i=1; i<=columns; i++){
		cols.push({
			id:i,
			editor:"text",
			header:{
				text: nms.encode[i]
			},
			template:function(obj, common, value, column){
				return cell_template(view, obj, common, value, column);
			},
			cssFormat:function(value, obj, row, column){
				const rendered = getRenderedValue(view, row, column);
				const cell = view._mPage.getCell(row-1, column-1);
				const style = cell ? cell.style : "";

				const conditional = getConditionCss(view, row, column, rendered);

				return `${style} ${conditional}`;
			}
		});
		view.callEvent("onColumnInit",[cols[i]]);
	}
	grid.refreshColumns(cols);

	const data = [];
	for (let i=1; i<=rows; i++)
		data.push({ id:i });

	grid.parse(data);
}

export function getRenderedValue(view, row, col){
	const htmlFilter = /<[^>]*>/gi;
	return cell_template(view, {id: row}, null, null, {id: col}, true).replace(htmlFilter, "");
}

// nowrap by default
function setDefaultCss(view){
	webix.html.addStyle("#"+view._table.$view.id+".webix_dtable .webix_cell { white-space:nowrap;}");
}
