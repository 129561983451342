import {Dialog} from "./common";
import {range} from "../helpers/column_names";
import {parseChart} from "../mathematics/writer";

export const action = "add-sparkline";

const labels = webix.i18n.spreadsheet.labels;

function getTypes(){
	return  [
		{id:"line", value:labels["line-chart"]},
		{id:"spline", value:labels["spline-chart"]},
		{id:"splineArea", value:labels["splinearea-chart"]},
		{id:"area", value:labels["area-chart"]},
		{id:"bar", value:labels["bar-chart"]},
		{id:"pie", value:labels["pie-chart"]}
	];
}

export class DialogBox extends Dialog{
	$show(box, form){
		this.cell = this.view.getSelectedId();
		if(!this.cell)
			return false;

		const els = form.elements;
		this.view.$handleSelection = function(st, end, a, b){
			els.range.setValue(a+":"+b);
			return false;
		};

		els.range.setValue("");

		//sync state of dialog to data with  the selected cell
		let value = this.view.getCellValue(this.cell.row, this.cell.column);
		let data = parseChart(value);
		if (data){
			form.blockEvent();
			els.type.setValue(data.type);
			els.range.setValue(data.range);
			if (data.color){
				els.color_def.setValue(data.color);
				els.color_pos.setValue(data.color);		
			}
			if (data.negativeColor)
				els.color_neg.setValue(data.negativeColor);
			form.unblockEvent();
			this.renderPreview();
		}	

		els.range.focus();
	}
	$hide(){
		this.view.$handleSelection = null;
	}
	$init(){
		return {
			view: "ssheet-dialog",
			head:labels["sparkline-title"],
			move: true,
			position: "center",
			body:{
				view:"form", visibleBatch:1, on:{ onChange : ()=> this.renderPreview() },  elements:[
					{view: "richselect", name:"type", label:labels["sparkline-type"], value:"line", labelPosition:"left", suggest:{
						view:  "ssheet-form-suggest",
						data:getTypes()
					}, on:{
						onChange: function(type){
							const form = this.getFormView();
							switch(type){
								case "pie":
									form.showBatch(3);
									break;
								case "bar":
									form.showBatch(2);
									break;
								default:
									form.showBatch(1);
							}
						}
					}},
					{ view:"text", label:labels["sparkline-range"], name:"range"},
					{ view:"ssheet-colorpicker", label:labels["sparkline-color"], name:"color_def", id:"add_sparkline_color", value:"#6666FF", batch:"1"},
					{ view:"ssheet-colorpicker", label:labels["sparkline-positive"], name:"color_pos", value:"#6666FF", batch:"2"},
					{ view:"ssheet-colorpicker", label:labels["sparkline-negative"], name:"color_neg", value:"#FF6666", batch:"2"},
					{ view:"formlate", name:"preview", borderless:true, css:"webix_ssheet_preview", height:50 }
				]
			},
			on:{
				onSaveClick: () => this.okClick(),
				onCancelClick: () => this.close()
			}
		};
	}
	checkRange(text){
		if(text && range(text, this.view))
			return true;
		this.view.alert({text:labels["error-range"]});
	}
	okClick(){
		const form = this.$dialog.getBody();
		const data = form.getValues();
		data.range = data.range.toUpperCase();

		if (data.range && this.checkRange(data.range)){
			const config = this.sparkConfig(data);

			this.view.addSparkline(this.cell.row, this.cell.column, config);
			this.close();
		}
	}

	renderPreview(){
		const form = this.$dialog.getBody();
		const data = form.getValues();
		data.range = data.range.toUpperCase();

		if (data.range && this.checkRange(data.range)){
			const pos = range(data.range, this.view);
			let i,
				values = this.view._mPage.getRange(pos[0]-1, pos[1]-1, pos[2]-1, pos[3]-1),
				config = this.sparkConfig();
			for(i =0 ; i < values.length; i++)
				values[i] = values[i]||0;
			form.elements.preview.setValue(webix.Sparklines.getTemplate(config)(values, { width:200, height: 40 }));
		}
		else
			form.elements.preview.setValue("");
	}

	sparkConfig(config){
		const form = this.$dialog.getBody();
		const data = form.getValues();
		config = config || { type: data.type  };
		if (data.type === "bar"){
			config.color = data.color_pos;
			config.negativeColor = data.color_neg;
		}
		else if(form.elements.color_def.isVisible())
			config.color = data.color_def;

		return config;
	}
}