import {ignoreReset, ignoreUndo}  from "../operations/undo";
import group  from "../operations/undo_group";
import {adjustSpan}  from "../operations/spans";
import {updateMaxColumn} from "../helpers/column_names";

export function init(view){
	view.attachEvent("onCommand", (action, start, end) => {
		if (action.id == "add" || action.id == "del"){
			var area = view._table.getSelectArea();
			if(area && !(start && end)){
				start = area.start;
				end = area.end;
			}

			if (start && end){
				if(action.group == "column" && action.id == "add")
					updateMaxColumn(view.config.columnCount + (end.column - start.column) + 1);

				group.set(function(){
					process(action, start, end, view);
				});
			}
		}
	});

	view.attachEvent("onUndo", (action, row, column, value) => {
		if (action == "grid-change"){
			loadChangedData(view, value);
		}
	});
}

function process(action, start, end, view) {
	let select = view._table.getSelectArea();
	const state = view._table.getScrollState();

	const oldData = view.serialize({math: true, viewIds:true});
	const newData = webix.copy(oldData);
	const data = {value:oldData, newValue:newData};

	const spanData = [];
	const spans = newData.spans;
	for (let i in spans){
		const span = adjustSpan(spans[i], action, start, end, view, newData.data, spanData);
		if (span[2] <= 0 || span[3] <= 0)
			spans.splice(i, 1);
	}

	const values = getUpdateValues(action, start, end);
	ignoreUndo(function(){
		view.callEvent("onAction", ["before-grid-change", {
			name: values.name,
			inc: values.inc,
			data: newData,
			start: start
		}]);
	},view);

	if (action.group == "column"){
		if (action.id == "add")
			_addColumn(action, start, end, view);
		else if (action.id == "del")
			_delColumn(action, start, end, view);
	}
	else if (action.group == "row") {
		if (action.id == "add")
			_addRow(action, start, end, view);
		else if (action.id == "del")
			_delRow(action, start, end, view);
	}

	// muon updates data and ranges
	const updatedData = view.serialize({math: true});
	newData.data = updatedData.data;
	newData.ranges = updatedData.ranges;

	spanData.forEach(item => {
		newData.data.push(item);
	});

	view.callEvent("onAction", ["grid-change", data]);

	ignoreUndo(function() {
		loadChangedData(view, newData);
		view._table.scrollTo(state.x, state.y);
	},view);

	if (select){
		select = fixSelectArea(select, view.config);
		if (select)
			view.$$("cells").addSelectArea(select.start, select.end);
	}
}

function fixSelectArea(select, config){
	const rows = config.rowCount;
	const cols = config.columnCount;
	if (select.start.row*1 > rows) select.start.row = rows;
	if (select.end.row*1 > rows) select.end.row = rows;
	if (select.start.column*1 > cols) select.start.column = cols;
	if (select.end.column*1 > cols) select.end.column = cols;

	return select;
}

function loadChangedData(view, data){
	// data loading resets undo history, and
	// we need to preserve it
	ignoreReset(function(){
		view.$handleSelection = null;
		view.parse(data);
	});
}

function getUpdateValues( action, start, end){
	var name = action.group,
		inc = action.id=="add"?1:(action.id=="del"?-1:0);

	if(inc) {
		// span support
		if (name == "row")
			inc += inc * (end.row - start.row);
		if (name == "column")
			inc += inc * (end.column - start.column);
	}
	return {name: name, inc: inc};
}

function _addColumn(action, start, end, view) {
	let count = end.column - start.column + 1;

	view._mPage.addColumn(start.column-1, count);
	view.config.columnCount += count;

	view.callEvent("onColumnOperation", [action, start, end]); //add item to history
}

function _delColumn(action, start, end, view) {
	let count = end.column - start.column + 1;

	if (view.config.columnCount === count){
		if (start.column == end.column)
			return; //deleting last column, ignoring
		//prevent deleting all columns
		end.column--;
		count--;
	}

	view._mPage.removeColumn(start.column-1, count);
	view.config.columnCount -= count;

	view.callEvent("onColumnOperation", [action, start, end]);
}

function _addRow(action, start, end, view) {
	let count = end.row - start.row + 1;

	view._mPage.addRow(start.row-1, count);
	view.config.rowCount += count;

	view.callEvent("onRowOperation", [action, start, end]);
}

function _delRow(action, start, end, view) {
	let count = end.row - start.row + 1;

	if (view.config.rowCount === count){
		if (start.row == end.row)
			return; //deleting last column, ignoring
		//prevent deleting all columns
		end.row--;
		count--;
	}

	view._mPage.removeRow(start.row-1, count);
	view.config.rowCount -= count;

	view.callEvent("onRowOperation", [action, start, end]);
}