import {getStyle, getTextSize} from "./styles";
import {save} from "./save";
import * as arr from "../helpers/array";

export function init(view){

	if (view.config.resizeCell){
		var grid = view.$$("cells");
		grid.define("resizeRow", { headerOnly: true, size: 10 });
		grid.define("resizeColumn", { headerOnly: true, size: 10 });
		grid.define("fixedRowHeight", false);

		grid.attachEvent("onRowResize", (id) => {
			view.$$("cells").refreshSelectArea();
			save(view, "sizes", { row: id, column: 0, size: view.$$("cells").getItem(id).$height });
		});
		grid.attachEvent("onColumnResize", (id) => {
			view.$$("cells").refreshSelectArea();
			save(view, "sizes", { row: 0, column: id, size: view.getColumn(id).width });
		});

		// undo
		view.attachEvent("onUndo", (type, row, column, value) => {
			if(type == "c-resize" || type == "r-resize")
				_undoResize(view, row, column, value);
		});
	}

	view.attachEvent("onDataParse", (data) => {
		load(view,data);
	});

	
	view.attachEvent("onDataSerialize", (data) => {
		serialize(view,data);
	});

	view.attachEvent("onAction", (action, p)=> {
		if(action == "before-grid-change")
			updatePosition(view, p.name, p.inc, p.data, p.start);
	});

	view.attachEvent("onBeforeStyleChange", function(row, column, n, o){
		if (o && o.props.wrap === "wrap" && (!n || n.props.wrap != o.props.wrap)){
			delete view._table.getItem(row).$height;
		}
	});
	view.attachEvent("onStyleChange", _adjustRowHeight);
	view.attachEvent("onCellChange",  _adjustRowHeight);
}

function serialize(view,data){
	var sizes = [];
	var grid = view.$$("cells");
	var columns = grid.getState().order;
	var order = grid.data.order;

	var defWidth = grid.config.columnWidth;
	var defHeight = grid.config.rowHeight;

	for (let i = 1; i < columns.length; i++){
		var width = grid.getColumnConfig(columns[i]).width;
		if (width && width != defWidth)
			sizes.push([0, i, width]);
	}

	for (let i = 0; i < order.length; i++){
		var height = grid.getItem(order[i]).$height;
		if (height && height != defHeight)
			sizes.push([order[i]*1, 0, height]);
	}
	
	data.sizes = sizes;
}

function load(view,data){
	if (data.sizes){
		for (let i=0; i<data.sizes.length; i++){
			const size = data.sizes[i];
			if (size[0]*1 !== 0){
				const row = view.$$("cells").getItem(size[0]);
				if(row) row.$height = size[2]*1;
			} else {
				const col = view.getColumn(size[1]);
				if(col) col.width = size[2]*1;
			}
		}
		if (data.sizes.length)
			view.refresh(true);
	}
}

function _undoResize(view, row, column, value){
	if(row){
		view.$$("cells").getItem(row).$height = value;
		save(view, "sizes", { row:row, column: 0, size: value });
	}
	else{
		view._table.setColumnWidth(column, value);
	}
	// update area selection
	view._table.refreshSelectArea();
}

function updatePosition(view, name, inc, data, start){
	let i,
		sizes = data.sizes,
		nsizes = [];

	if(inc){
		for(i = sizes.length - 1; i >= 0; i--){
			let [row, column, size] = sizes[i];

			if(row && name == "row" && row >= start.row){
				if(inc < 0 && row < start.row - inc)
					continue;
				nsizes.push([row*1 + inc, column, size]);
			}
			else if(column && name == "column" && column >= start.column){
				if(inc < 0 && column < start.column - inc)
					continue;
				nsizes.push([row, column*1 + inc, size]);
			}
			else
				nsizes.push(sizes[i]);
		}

		if(inc > 0){
			//col/row add: set sizes according to the selected area
			if(name == "column"){
				for(let i = 0; i < inc; i++){
					const size = arr.find(sizes, arr => arr[1] == start.column + i);
					if(size)
						nsizes.push([0, start.column + i, size[2]]);
				}
			}
			else{
				for(let i = 0; i < inc; i++){
					const size = arr.find(sizes, arr => arr[0] == start.row + i);
					if(size)
						nsizes.push([start.row + i, 0, size[2]]);
				}
			}
		}

		data.sizes = nsizes;
	}
}

function _adjustRowHeight(row, column){
	const view = this;

	if(view._loading_data)
		return;

	const style = getStyle(view, {row:row, column:column});

	//changing styles or content of wrapped cell -> resize the related row
	if (style && style.props.wrap){
		const grid = view._table;
		const item = grid.getItem(row);
		let height = item.$height || grid.config.rowHeight;

		grid.eachColumn(function(col, config){
			const style = getStyle(view, {row:row, column:col});
			//include only cells with active wrap into calculation 
			if(style && style.props && style.props.wrap === "wrap"){
				let text = this.getText(row, col);
				let baseWidth = config.width;

				const span = grid.getSpan(row, col);
				let spanHeight;

				if(span){
					const node = grid.getSpanNode({row:span[0], column:span[1]});
					baseWidth = node.offsetWidth;
					spanHeight = node.offsetHeight;
				}

				if(view.getCellEditor(row, col) || view.getCellFilter(row, col))
					baseWidth += 20;

				let textHeight = getTextSize(view, text, style.id, baseWidth).height;

				if(spanHeight)
					textHeight = spanHeight >= textHeight ? 0 : textHeight - spanHeight + height;

				height = Math.max(height, textHeight);
			}
		});

		item.$height  = height;
	}
}
