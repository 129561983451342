import {image, chart} from "../mathematics/writer";
import {addMath} from "../mathematics/methods";
import {range as getRange} from "../helpers/column_names";

export function addImage(row, column, url){
	this.setCellValue(row, column, image(url));
	this.refresh();
}

export function addSparkline(row, column, config){
	let value = chart(config);
	this.setCellValue(row, column, value);
	this.refresh();
}

export function getSheetData(name){
	const sheet = this._mData.getPage(name);

	if(sheet)
		return {
			getValue: (r, c, math)=>{
				return _normalizeVal(sheet.getValue(r-1, c-1, math));
			},
			getRangeValue: (range)=>{
				const pos = getRange(range, this, name);
				if (!pos) return [];

				const page = pos[4] == name ? sheet : this._mData.getPage(pos[4]);
				return page.getRange(pos[0]-1, pos[1]-1, pos[2]-1, pos[3]-1).map(_normalizeVal);
			}
		};
}

function _normalizeVal(val){
	return !val && val !== 0 ? "" : val;
}

export function registerMathMethod(name, handler){
	addMath(this, name, handler, true);
}

export function recalculate(){
	this._mData.getStore().recalculate();
	this.callEvent("onMathRefresh",[]);
	this.refresh();
}

export function setPlaceholder(name, value){
	if(typeof name == "object"){
		value = name;
		name = "*";
	}

	this._mData.getStore().setPlaceholder(name, value);

	this.refresh();
	this.callEvent("onMathRefresh",[]);
}