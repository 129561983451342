import {range as getRangePos} from "../helpers/column_names";
import {cell_template} from "../table";

class Ranges{
	constructor(view){
		this._master = view;
	}
	clear(scope){
		const sheet = typeof scope == "string" ? scope : this._master.getActiveSheet();
		const ranges = this.serialize(sheet);
		ranges.forEach(range => {
			if(scope === true){
				if(range[2])
					this.remove(range[0], scope);
				return;
			}
			else
				this.remove(range[0], scope);
		});
	}
	_normalizeName(name, scope){
		if(scope !== true)
			name = (scope || this._master.getActiveSheet())+"!"+name;
		return name;
	}
	_normalizeRange(range, scope){
		const rangeParts = range.split("!");
		if(rangeParts.length == 1)
			range = (typeof scope == "string" ? scope : this._master.getActiveSheet()) + "!" + range;
		return range;
	}
	add(name, range, scope){
		const view = this._master;

		view.callEvent("onAfterRangeSet", [name, range, scope]);

		name = this._normalizeName(name, scope);

		view._mData.getRanges().add(name, this._normalizeRange(range, scope));
		view.callEvent("onMathRefresh", []);
	}
	getCode(name, scope){
		name = this._normalizeName(name, scope);

		const range = this._master._mData.getRanges().get(name);
		if(range)
			return range.source;
	}
	remove(name, scope){
		name = this._normalizeName(name, scope);

		this._master._mData.getRanges().remove(name);
		this._master.callEvent("onMathRefresh", []);
	}
	parse(data, sheet){
		if(!data)
			return;
		for(let i = 0; i < data.length; i++){
			const ranges = this._master._mData.getRanges();
			let [name, range, globalRange] = data[i];
			name = this._normalizeName(name, globalRange||sheet);

			if(!ranges.get(name))
				ranges.add(name, this._normalizeRange(range, sheet));
		}
	}
	serialize(sheet){
		sheet = sheet || this._master.getActiveSheet();
		const allRanges = this._master._mData.getRanges().serialize();
		const out = [];

		for(let i = 0; i < allRanges.length; i++){
			const arr = allRanges[i];
			const nameParts = arr[0].split("!");
			const globalRange = nameParts.length == 1;

			if(globalRange || nameParts[0] == sheet)
				out.push([
					nameParts[ globalRange ? 0 : 1 ],
					arr[1],
					globalRange
				]);
		}

		return out;
	}
	getRanges(sheet){
		const data = this.serialize(sheet);
		return data.map(arr => {
			return {
				name: arr[0],
				range: arr[1],
				global: arr[2]
			};
		});
	}
}

export function init(view) {
	view.ranges = new Ranges(view);

	view.attachEvent("onDataSerialize", data => {
		data.ranges = view.ranges.serialize();
	});
}

function nullFilter(){ 
	const locale = webix.i18n.spreadsheet.labels;
	return { id:"$empty", value:"", $value:"<span class='webix_ssheet_empty'>"+locale["dropdown-empty"]+"</span>" };
}

function getRange(view, r1, c1, r2, c2){
	const out = [];
	const activeSheet = view._mPage === this;

	for (var i = r1-1; i <= r2-1; i++) {
		for (var j = c1-1; j <= c2-1; j++){
			let obj = this.getCell(i, j);
			let cell;

			if(!obj)
				cell = {
					value: ""
				};
			else
				cell = webix.copy(obj);

			if(activeSheet)
				cell.$value = cell_template(view, {id: i+1}, null, null, {id:j+1}, true);
			else
				delete cell.format;

			cell.id = cell.value;
			out.push(cell);
		}
	}

	return out;
}

export function rangeOptions(view, text, extra){
	let values = [];
	if (typeof text == "string"){
		const pos = getRangePos(text, view);
		if (pos){
			let page = view._mPage;
			if(pos[4])
				page = view._mData.getPage(pos[4]);

			values = page ? getRange.apply(page, [view, ...pos]) : [];
		}
	}
	else if (webix.isArray(text))
		for (let i=0; i<text.length; i++){
			let v = (text[i] || text[i] === 0) ? text[i] : "";
			v = (typeof v === "object") ? webix.copy(v) : { id:v, value:v };
			values.push(v);
		}

	if (extra){
		if (extra.unique){
			const test = {};
			for (let i=values.length-1; i>=0; i--){
				if (test[values[i].id])
					values.splice(i, 1);
				else
					test[values[i].id] = true;
			}
		}

		let empty = false;
		for (let i=values.length-1; i>=0; i--){
			values[i].value = String(values[i].value);
			if (values[i].value === ""){
				values.splice(i, 1);
				empty = true;
			}
		}

		if (extra.order)
			values.sort(function(objA, objB){
				const sorters = view.$$("cells").data.sorting.as;
				const a = objA.value;
				const b = objB.value;

				return webix.rules.isNumber(a) && webix.rules.isNumber(b) ? sorters.int(a, b) : sorters.string(a, b);
			});

		if (empty && extra.filter)
			values.unshift( nullFilter() );

		if (extra.empty)
			values.unshift({ id:"$empty", $empty:true, value:"" });
	}
	return values;
}