import group from "./undo_group";
import {getType} from "./types";
import {setSpanCss} from "./spans";
import {rangeOptions} from "../mathematics/ranges";
import {changeRange, eachRange, toRange} from "../helpers/column_names";
import {conditions} from "../helpers/conditional";

export function init(view){
	const table = view._table;

	view.attachEvent("onReset", () => reset(view));
	reset(view);

	table.on_click.ssheet_filter_sign = (e, id) => {
		const filter = table._ssFilters[id.row][id.column];
		const mode = filter.mode || getFilterMode(view, id.row, id.column);

		let options = [];
		if (filter.options){
			if (typeof filter.options == "string")
				options = rangeOptions(view, filter.options, { unique:true, order:true, filter:true });
			else if (webix.isArray(filter.options))
				options = rangeOptions(view, filter.options, { filter:true });
		}
		view.callEvent("onCommand", [{id:"start-filter", cell:id, mode, options, filter}]);
	};
	table.on_dblclick.ssheet_filter_sign = () => false;

	view.attachEvent("onCommand", (cm) => {
		if (cm.id === "create-filter")
			toggleFilters(view);
	});

	let refilter = false;
	view.attachEvent("onUndo", (type, row, column, value) => {
		if (type == "filter"){
			value = (typeof value == "object") ? value : null;
			view.setCellFilter(row, column, value);
			refilter = true;
		}
		else if (type == "apply-filter"){
			const filter = view.getCellFilter(row, column);

			filter.value = value;
			delete filter.handler;
			if (value && value.condition){
				const mode = filter.mode || getFilterMode(view, row, column);
				filter.handler = getFilterFunction(mode, filter.value);
			}
			refilter = true;

			// update css
			const add = value && isValueActive(value);
			table[add?"addCellCss":"removeCellCss"](row, column, "ss_filter_active");
			setSpanCss(view, row, column, "ss_filter_active", add);
		}
	});

	view.attachEvent("onAfterUndo", () => {
		if (refilter){
			view.filterSpreadSheet();
			refilter = false;
		}
	});

	view.attachEvent("onAction", (action, p) => {
		if (action == "filter"){
			setSpanCss(view, p.row, p.column, "ss_filter", p.newValue);
			setSpanCss(view, p.row, p.column, "ss_filter_active", (p.newValue && p.newValue.value && isValueActive(p.newValue.value)));
			markBarFilter(view);
		}
		else if (action == "before-grid-change")
			updatePosition(view, p.name, p.inc, p.data, p.start);
	});
}

function reset(view){
	view._table._ssFilters = {};
	markBarFilter(view);
}

export function getFilters(table){
	const filters = table._ssFilters;
	const all = [];
	for (let row in filters)
		for (let column in filters[row])
			all.push(filters[row][column]);
	return all;
}

export function serialize(view,data){
	const filters = getFilters(view._table);
	const result = data.filters = [];

	for (let i = 0; i<filters.length; i++){
		let {row, column, options, mode, value} = filters[i];
		value = value && webix.copy(value);
		result.push([row, column, {value, options, mode}]);
	}
}

export function load(view,data){
	const filters = data.filters;

	if (filters){
		for (let i = 0; i < filters.length; i++){
			view.setCellFilter(filters[i][0], filters[i][1], (filters[i][2] || {options:""}));
		}
		view.filterSpreadSheet();
	}
}

export function clearFilters(view, range){
	let refilter = false;
	eachRange(range, view, function(view, cell){
		let filter = view._table._ssFilters[cell.row];
		if (filter){
			if (filter[cell.column]){
				refilter = true;
				delete filter[cell.column];

				view._table.removeCellCss(cell.row, cell.column, "ss_filter");
				view._table.removeCellCss(cell.row, cell.column, "ss_filter_active");

				setSpanCss(view, cell.row, cell.column, "ss_filter", false);
				setSpanCss(view, cell.row, cell.column, "ss_filter_active", false);
			}
		}
	});

	if (refilter)
		view.filterSpreadSheet();
}

export function isFilter(view, row, column){
	const filters = view._table._ssFilters;
	return filters[row] && !!filters[row][column];
}

export function calibrateRange(view, row, column, range){
	if(!range)
		range = {end:{row:row}};

	if (range.end){
		var endRow = range.end.row;
		range = getFilterRange(view, row, column, endRow);
	}
	return range;
}

export function isValueActive(value){
	return !!(value.includes || (value.condition && value.condition.filter));
}

function isFilterExists(view){
	const filters = view._table._ssFilters;
	for (let row in filters)
		for (let column in filters[row])
			if (filters[row][column]) return true;
	return false;
}

function markBarFilter(view){
	const value = isFilterExists(view);
	view.callEvent("onCommand", [{id:"toolbar-update", name:"create-filter", value}]);
}


function getFilterRange(view, row, column, endRow){
	if (row === endRow){
		for (endRow = row; endRow < view.config.rowCount; endRow++){
			if(!view.getCellValue(endRow+1, column))
				break;
		}
	}
	return toRange(row+1, column, endRow, column);
}

export function getFilterMode(view, row, column){
	for (let i=row+1; i<view.config.rowCount; i++){
		const value = view.getCellValue(i, column);

		if (value || value === 0){
			const type = getType(view, i, column);
			return (type == "number" || type == "date") ? type : "text";
		}
		else if (value !== "")
			break;
	}
	return "text";
}

function toggleFilters(view){
	const range = view._table.getSelectArea();

	if (isFilterExists(view)){
		group.set(function(){
			view.removeFilters();
		});
		if (range)
			view._table.addSelectArea(range.start, range.end);
	}
	else if (range)
		group.set(function(){
			let row = range.start.row;
			for (let i=range.start.column; i<=range.end.column; i++){
				view.setCellFilter(row, i, { options:range });
			}
		});
	markBarFilter(view);
}

function updatePosition(view, name, inc, data, start){
	const filters = data.filters;

	if (inc){
		for(let i=filters.length-1; i>=0; i--){
			let [row,column,filter] = filters[i];
			if (name == "row" && row >= start.row || name == "column" && column >= start.column){

				if (name == "row"){
					if (row < start.row - inc){	//delete filter mark if row was deleted
						filters.splice(i,1);
						continue;
					} else						//update mark position if upper row was deleted
						filters[i][0] = row*1 + inc;
				}
				else if (name == "column"){
					if (column < start.column - inc){
						filters.splice(i, 1);
						continue;
					} else 
						filters[i][1] = column*1 + inc;
				}
				filter.options = changeRange(filter.options, name, inc, start);
			}
		}
	}
}

export function pasteFilter(view, extra, row, col, cutted, translate){
	const filter = extra.filter;

	filter.options = changeRange(filter.options, "row", translate.row, {column:extra.col, row:extra.row});
	filter.options = changeRange(filter.options, "column", translate.column, {column:extra.col, row:extra.row});

	delete filter.value; delete filter.handler;
	view.setCellFilter(row, col, filter);
}

export function getSingleOption(mode, type){
	const c = conditions[mode];
	for (let i=0; i<c.length; i++)
		if (c[i].id == type) return c[i];
}

export function getConditions(name){
	return webix.copy( conditions[name] );
}

export function getFilterFunction(mode, value){
	const { includes, condition } = value;
	const opt = getSingleOption(mode, condition.type);
	const handler = opt ? opt.handler : function(){ return true; };

	if (includes){
		for (let i=0; i<includes.length; i++)
			includes[i] = String(includes[i]);
	}

	return function(value){
		if (includes){
			return includes.indexOf(value) !== -1;
		} else {
			return condition.filter === "" || handler(value, condition.filter);
		}
	};
}