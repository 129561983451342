import group from "../operations/undo_group";

export function freezeColumns(column){
	const grid = this.$$("cells");

	if( webix.isUndefined(column) ){
		let sel = this.getSelectedId(true);
		column = sel.length?sel[0].column:0;
	}

	const base = grid.config.columns[0].id == "rowId" ? 1 : 0;
	let index = base;
	if (column && column != this._frozenColumns){
		for (let i = column; i > 0; i--) {
			index = grid.getColumnIndex(i)+1;
			if(index)
				break;
		}
		if (!index) index = base;
	}

	group.set(function(){
		checkSpans(this, "column", column);
		grid.unselect();
		grid.define("leftSplit", index);
		grid.refreshColumns();

		this.callEvent("onAction", [ "freeze-column", { value: (this._frozenColumns || 0), newValue: column}]);
		this._frozenColumns = column || 0;
	},this);
}

export function freezeRows(row){
	const grid = this.$$("cells");

	if( webix.isUndefined(row) ){
		let sel = this.getSelectedId(true);
		row = sel.length?sel[0].row:0;
	}

	let index = 0;
	if (row && row != this._frozenRows){
		for (let i = row; i > 0; i--) {
			index = grid.getIndexById(i)+1;
			if(index)
				break;
		}
	}

	group.set(function(){
		checkSpans(this, "row", row);
		grid.unselect();
		grid.define("topSplit", index);
		grid.refreshColumns();

		this.callEvent("onAction", [ "freeze-row", { value: this._frozenRows, newValue: row}]);
		this._frozenRows = row || 0;
	},this);
}

function checkSpans(view, type, num){
	const grid = view.$$("cells");
	const spans = grid.getSpan();
	let changed;

	for(let row in spans){
		if(type == "row" && row > num)
			continue;

		for (let col in spans[row]){
			if(type == "column" && col > num)
				continue;

			const span = spans[row][col];
			const spanSize = type == "row" ? (row*1) + span[1] - 1 : (col*1) + span[0] - 1;

			if(spanSize > num){
				changed = true;
				changeSpan(
					view,
					[row, col],
					type == "row" ? span[0] : span[0] - (spanSize - num),
					type == "row" ? span[1] - (spanSize - num) : span[1]
				);
			}
		}
	}

	if(changed)
		view.refresh();
}

function changeSpan(view, span, x, y){
	view.splitCell(span[0], span[1], true);
	view.combineCells(
		{
			cell:{
				row:span[0],
				column:span[1]
			},
			x,
			y,
		},
		true
	);
}