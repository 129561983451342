import * as flt from "../operations/filter";

export function setCellFilter(row, column, filter){
	const line = this._table._ssFilters[row] = this._table._ssFilters[row] || {};
	const value = line[column] || null;

	if (filter && (webix.isArray(filter) || typeof filter == "string"))
		filter = { options:filter };
	line[column] = filter;

	if (filter && typeof filter == "object"){
		this._table.addCellCss(row,column, "ss_filter");
		if (filter.value && flt.isValueActive(filter.value))
			this._table.addCellCss(row,column, "ss_filter_active");

		webix.extend(filter, {row, column}, true);
		filter.options = flt.calibrateRange(this, row, column, filter.options);

		if (!filter.handler && filter.value && filter.value.condition){
			const mode = filter.mode || flt.getFilterMode(this, row, column);
			filter.handler = flt.getFilterFunction(mode, filter.value);
		}
	} else {
		delete this._table._ssFilters[row][column];
		this._table.removeCellCss(row,column, "ss_filter");
		this._table.removeCellCss(row,column, "ss_filter_active");
	}
	this.callEvent("onAction", ["filter", { row, column, value, newValue:filter }]);
}

export function getCellFilter(row, column){
	let line = this._table._ssFilters[row] || {};
	return line[column] || null;
}

export function removeFilters(){
	const filters = flt.getFilters(this._table);

	for	(let i=0; i<filters.length; i++){
		const {row, column} = filters[i];
		this.setCellFilter(row, column, null);
	}

	this._table._ssFilters = {};
	this._table.data.filter((obj) => {
		return this.isRowVisible(obj.id);
	});
}


export function filterSpreadSheet(){
	this._table.data.silent(function(){
		const filters = flt.getFilters(this._table);

		// hidden rows
		this._table.data.filter((obj) => {
			return this.isRowVisible(obj.id);
		});

		for (let i=0; i<filters.length; i++){
			const {row, column, handler} = filters[i];

			let all = false;
			this._table.data.filter(obj => {
				if (all || obj.id*1 <= row*1) return true;

				let value = this._mPage.getValue(obj.id - 1, column - 1);

				if (!value && value !== "") return all = true;
				return (typeof handler == "function") ? handler( String(value) ) : true;
			}, 1, true);
		}
	}, this);

	this._table.callEvent("onAfterFilter", []);
	this._table.refresh();
}
