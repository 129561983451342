import {addMethod} from "@xbs/muon";

export function init(view){
	const math = {
		IMAGE: url => `<img class="webix_ssheet_cimage" src="${webix.template.escape(url)}">`,
		HYPERLINK: (url, text) => `<a target="blank" href="${webix.template.escape(url)}">${webix.template.escape(text || url)}</a>`,
		SPARKLINE: (arr, type, color1, color2) => {
			const config = { type, color: color1, negativeColor:color2  };
			const width = type == "pie"? 60: 150;

			for(let i = 0 ; i < arr.length; i++)
				arr[i] = arr[i]||0;

			return webix.Sparklines.getTemplate(config)(arr, { width, height: 35 });
		}
	};

	const keys = Object.keys(math);
	keys.forEach((key, i) => addMath(view, key, math[key], i == keys.length - 1));
}

export function addMath(view, name, handler, sort){
	name = name.toUpperCase();
	addMethod(name, handler);
	if(view.$$("liveEditor")){
		const sid = view.$$("liveEditor").config.suggest;
		const list = webix.$$(sid).getList();
		if(!list.exists(name)){
			list.add({id:name, value:name});
			if(sort)
				list.sort("value", "asc", "string");
		}
	}
}

export function getJsDateFromExcel(excelDate) {
	const date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
	return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function getExcelDateFromJs(jsDate) {
	const returnDateTime = 25569 + ((jsDate.getTime() - (jsDate.getTimezoneOffset() * 60000)) / (86400 * 1000));
	return returnDateTime.toString();
}