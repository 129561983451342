webix.protoUI({
	name: "ssheet-series",
	$init: function(config){
		config.rows = [
			{
				view: "scrollview",
				borderless: true,
				body: {
					view: "accordion",
					css: "webix_ssheet_accordion",
					margin: webix.skin.$active.layoutMargin.form,
					multi: true,
					type: "clean",
					rows: [
						this.getSectionConfig()
					]
				},
				on:{
					onAfterScroll:function(){
						//close possibly open selector popups
						webix.callEvent("onClick", []);
					}
				}
			},
			{ view:"button", label:webix.i18n.spreadsheet.labels["add-line"], click:()=>{
				this.addSection();
			}}
		];
	},
	getSectionConfig: function(i){
		const view = this;
		const labels = webix.i18n.spreadsheet.labels;
		const trashIcon = "<span class='webix_icon webix_ssheet_series_icon wxi-trash'></span>";
		const padding = webix.skin.$active.dataPadding-2;

		return {
			view:"accordionitem",
			header:`${labels["chart-item"]} ${i ? i+1 : 1} ${trashIcon}`,
			body:{
				view:"form",
				padding,
				elementsConfig:{
					labelWidth:180
				},
				on:{
					onChange: () => {
						this.callEvent("onChange", []);
					}
				},
				elements:[
					{ view:"text", label:labels["chart-range"], name:"range", range:true, on:{
						onChange: function(val, oldVal) {
							const upper = val.toUpperCase();
							if(val != upper){
								this.blockEvent();
								this.setValue(upper);
								this.unblockEvent();
							}
							view.callEvent("onRangeChange", [upper, oldVal]);
						}
					}},
					{ view:"ssheet-colorpicker", label:labels["chart-series-color"], name:"color"},
					{ view:"checkbox", label:labels["chart-tooltip"], name:"tooltip" },
					{ view:"checkbox", label:labels["chart-label"], name:"label", hidden: !this.labelsVisible },
					{ view:"richselect", label:labels["chart-markers"], value:"square", name:"marker", hidden: !this.markersVisible,
						suggest:{
							view: "ssheet-form-suggest",
							data: [
								{id:"square", value:labels["chart-markers-square"]},
								{id:"triangle", value:labels["chart-markers-triangle"]},
								{id:"diamond", value:labels["chart-markers-diamond"]},
								{id:"round", value:labels["chart-markers-round"]}
							]
						}
					}
				]
			},
			onClick:{
				"wxi-trash": function(){
					const layout = this.getParentView();
					layout.removeView(this);

					const childs = layout.getChildViews();

					for (let i = 0; i < childs.length; i++) {
						childs[i].define({header:`${labels["chart-item"]} ${i+1} ${trashIcon}`});
						childs[i].refresh();
					}

					view.callEvent("onChange", []);
					return false;
				}
			}
		};
	},
	addSection: function(){
		const layout = this.getLayout();
		const lastIndex = layout.getChildViews().length;
		const layoutId = layout.addView(this.getSectionConfig(lastIndex));

		webix.$$(layoutId).queryView({name: "range"}).focus();
	},
	setValue: function(value){
		value = value || [];

		const layout = this.getLayout();
		const childs = layout.getChildViews();
		const count = childs.length;

		if(count > value.length)
			for(let i = count; i > value.length; i--)
				this.getLayout().removeView(childs[i-1]);
		else if(value.length > count)
			for(let i = value.length; i > count; i--)
				this.addSection();

		for(let i = 0; i < childs.length; i++){
			const form = childs[i].getChildViews()[0];
			const range = form.queryView({name: "range"});

			//block range events to ignore onRangeChange
			range.blockEvent();
			form.setValues(value[i]);
			range.unblockEvent();
		}
	},
	setSubValue: function(sub, value){
		sub.blockEvent();
		sub.setValue(value);
		sub.unblockEvent();
	},
	getLayout(){
		return this.queryView("scrollview").getBody();
	},
	hideSubviews(name, show){
		if(name == "marker")
			this.markersVisible = show;
		else
			this.labelsVisible = show;

		this.queryView({ name }, "all").forEach(view => {
			if(show)
				view.show();
			else
				view.hide();
		});
	},
	getValue: function(){
		const value = [];

		const layout = this.getLayout();
		layout.getChildViews().forEach(child => {
			value.push(child.getChildViews()[0].getValues());
		});
		return value;
	}
}, webix.ui.layout);