import { style_names } from "../operations/styles";
import { getType } from "../operations/types";
import { defaultStyles} from "../toolbar/defaults";
import { getFormatSource } from "../operations/formats";
import { getDimension } from "../helpers/array";
import { getConditionCss } from "../operations/conditional_formats";
import { getRenderedValue } from "../table";

export function init(view){
	const table = view._table;
	view.$exportView = function(options){
		const pdf = options.export_mode == "pdf";
		const excel = options.export_mode == "excel";
		const defaultOptions = {
			stubCells: true,
			header: false,
			footer:false,
			heights:true,
			rawValues: !pdf,
			filterHTML:true,
			spans: true,
			styles:true,
			math: !pdf,
			hidden: excel,
			xCorrection: table.config.header || options.hidden ? 1 : 0,
			ignore:{rowId:true},
			conditions:true
		};

		webix.extend(options, defaultOptions);

		if(excel || pdf)
			return _exportView(view, options);
		else
			return table;
	};

	table.$getExportValue = function(row, column, options){
		const value = view.getCellValue(row, column, options.math);
		const mode = options.export_mode;
		const math = options.math && value[0] == "=";
		if((mode == "pdf" || mode == "csv") && !math && !options.rawValues)
			return getRenderedValue(view, row, column);
		return typeof value == "string" && value.match(/^=(image|sparkline)\(/i) ? "" : value;
	};
}

function _exportView(view, options){
	const excel = options.export_mode == "excel";

	if(options.sheets === true) 
		options.sheets = view._sheets.map(function(s){ return s.name;});
	else if(!options.sheets || !options.sheets.length)
		options.sheets = [view._activeSheet];
	else if(typeof options.sheets == "string")
		options.sheets = [options.sheets];

	options.dataOnly = true;

	let data = [];
	let active = view._activeSheet;

	for(let i = 0; i<options.sheets.length; i++){
		const sheet = options.sheets[i];
		const id = sheet.id || sheet;

		view.showSheet(id);

		if(options.hidden)
			options.xCorrection = 1;

		const sheetOptions = sheet.options ? webix.extend(sheet.options, options) : webix.copy(options);
		if(id != active)
			sheetOptions._hidden = true;
		if(!sheetOptions.name)
			sheetOptions.name = id;

		const pdfTable = !excel && sheetOptions.display != "image";

		const serialized = view.serialize();
		const [rows, cols] = getDimension(serialized.data, serialized.spans, 0, 0);

		if(!excel){
			if(webix.isUndefined(sheetOptions.textBefore))
				sheetOptions.textBefore = sheetOptions.name;
			if(pdfTable){
				sheetOptions.ignore = sheetOptions.ignore || {};
				for(let i = cols+1; i <= view.config.columnCount; i++){
					sheetOptions.ignore[i] = true;
				}

				const filter = sheetOptions.filter || (()=>true);
				sheetOptions.filter = obj =>{
					return obj.id <= rows && filter.apply(this, arguments);
				};
			}
		}

		const sheetData = webix[excel ? "toExcel" : "toPDF"](view._table, sheetOptions);
		if(pdfTable && sheetData[0].scheme.length == 0)
			continue;

		if((excel || pdfTable) && sheetOptions.styles){
			const styles = sheetData[0].styles = _getStyles(view, sheetOptions, excel, rows, cols);

			if(pdfTable)
				_updatePdfData(styles, sheetData, sheetOptions, view, serialized);
		}

		data = data.concat(sheetData);

		if(excel){
			data[i].ranges = [];

			view.ranges.serialize(id).forEach(range => {
				const ref = range[1].split("!");
				data[i].ranges.push({
					Sheet: range[2] ? null : i,
					Name: range[0],
					Ref: ref[0]+"!"+ref[1].replace(/[0-9]+|[A-Z]+/gi, (match)=>`$${match}`)
				});
			});
		}
		else{
			if(options.autowidth)
				getAutowidth(sheetOptions, options);
		}
	}

	view.showSheet(active);
	delete options.dataOnly;
	return data;
}

function _updatePdfData(styles, sheetData, sheetOptions, view, serialized){
	const header = sheetOptions.header ? 1 : 0;

	if (sheetOptions.spans) {
		for (let idx in serialized.spans) {
			if (serialized.spans[idx][2] > 1) {
				let row = serialized.spans[idx][0] - 1 + header, // zero-based row position
					col = serialized.spans[idx][1] - 1, // zero-based col position
					cols = serialized.spans[idx][2];
				if (styles[row] && styles[row][col]) {
					styles[row][col].colspan = cols;
					// if colspan, then remove merged cells (else PDFJS will shift columns)
					const rowData = sheetData[0].exportData[row - header];
					if (rowData){
						// remove merged cell values
						rowData.splice(col + 1, cols - 1);
					}
					let style = Object.values(styles[row]);
					style.splice(col + 1, cols - 1); // remove merged cell styles
					styles[row] = style;
				}
			}
		}
	}

	const data = sheetData[0].exportData;
	for(let r = 0; r < data.length; r++){
		for (let c = 0; c < data[r].length; c++) {
			let height = view.$$("cells").config.rowHeight;

			if(sheetOptions.heights && serialized.sizes.length){
				for(let s = 0; s < serialized.sizes.length; s++){
					const rowSize = serialized.sizes[s][0];
					if(rowSize && rowSize == r+1){
						height = serialized.sizes[s][2];
						break;
					}
				}
			}

			const cellStyle = styles[r + header][c];
			const align = cellStyle.vertAlign;
			const pad = height*0.75 - cellStyle.fontSize; // available px for padding in top & bottom
			cellStyle.paddingTop = (align == "top" ? 0 : (align == "bottom" ? pad : pad / 2));
			cellStyle.paddingBottom = (align == "bottom" ? 0 : (align == "top" ? pad : pad / 2));
		}
	}
}

function getAutowidth(view, options){
	const prop = options.orientation && options.orientation == "landscape" ? "height" : "width";
	let width;

	if(view.$width)
		width = view.$width;
	else //'view' can be local settings and we need to compare them with common ones
		width = view[prop];

	options[prop] = Math.max(options[prop] || 0, width || 0);
}

function _safeColor(str){
	str = str.substring(1);
	if(str.length === 3) str = str+str;
	return str;
}

function _getDefaults(options){
	let d = defaultStyles;
	if(options.export_mode == "excel")
		return {
			font:{
				sz:d["font-size"].replace("px", "")*0.75,
				name:d["font-family"].replace(/'|,.*$/g, ""),
				//we do not export default color, but it is the right place to do it
			},
			alignment:{
				horizontal:d["text-align"],
				vertical:d["vertical-align"]=="middle"?"center":d["vertical-align"],
				wrapText:d["white-space"]!="nowrap"
			}
		};
	else
		return {
			fontSize: d["font-size"].replace("px", "")*0.75,
			//we do not export default color, but it is the right place to do it
			textAlign: d["text-align"],
			whiteSpace: (d["white-space"]!="nowrap")
		};
}

function _getStyles(view, options, excel, rows, cols){
	view.compactStyles();

	let result = [];
	const cached = {};
	const cachedConditions = {};
	const defaults = _getDefaults(options);

	if(excel && options.docHeader) result  = result.concat([{0:_getDocStyle(options.docHeader.css, defaults)}, {}]);
	if(options.header) result.push({});

	const delta = result.length;

	const grid = view.$$("cells");

	let rIndex = delta;
	grid.eachRow(row =>{
		if(row > rows)
			return;

		let cIndex = 0;
		grid.eachColumn(col => {
			if(cIndex > cols)
				return;

			if(cIndex >= options.xCorrection){
				const css = view.getStyle(row, col);
				const index = cIndex - options.xCorrection;

				result[rIndex] = result[rIndex] || {};

				let styles;
				if(css){
					if(cached[css.id])
						styles = webix.copy(cached[css.id]);
					else{
						styles = _getCellStyle(css.text, excel);

						for(let name in defaults){
							if(excel)
								webix.extend(styles[name], defaults[name]);
							else if (!styles[name])
								styles[name] = defaults[name];
						}

						cached[css.id] = webix.copy(styles);
					}
				}
				else
					styles = webix.copy(defaults);

				if(options.conditions){
					//not real excel condition, save only bg and color (sheetJS doesn't support)
					const condition = _getCellConditions(view, row, col, cachedConditions);
					if(condition)
						_updateStyleCondition(styles, condition, excel);
				}

				result[rIndex][index] = styles;
				result[rIndex][index].type = getType(view, row, col);
			}
			cIndex++;
		}, options.hidden);
		rIndex++;
	}, options.hidden);

	if(excel && options.docFooter) result  = result.concat([{},{0:_getDocStyle(options.docFooter.css, defaults)}]);

	return result;
}

function _getCellStyle(styles, excel){
	let str = styles.split(";");
	let stl = {};
	if(excel)
		stl = { font:{}, alignment:{}, border:{}};

	for(let s = 0; s<str.length; s++){
		if(str[s]){
			if(excel)
				_getExcelCellStyle(stl, str, s);
			else
				_getPdfCellStyle(stl, str, s);
		}
	}

	return stl;
}

function _getCellConditions(view, r, c, cached){
	const rendered = getRenderedValue(view, r, c);
	const css = getConditionCss(view, r, c, rendered);
	if(css){
		if(cached[css])
			return cached[css];
		const el = webix.html.create("div", {"class":css});
		document.body.appendChild(el);

		const computed = window.getComputedStyle(el);
		const bg = computed.backgroundColor;
		const color = computed.color;

		document.body.removeChild(el);

		cached[css] = [bg, color];
		return cached[css];
	}
}

function _updateStyleCondition(stl, condition, excel){
	let [bg, color] = condition.map(val => webix.color.rgbToHex(val));
	if(excel){
		stl.fill = {fgColor:{rgb:bg}};
		stl.font.color = {rgb:color};
	}
	else{
		stl.backgroundColor = bg;
		stl.color = color;
	}
}

function _getExcelCellStyle(stl, str, s){
	switch (style_names[s]){
		case "color":
			stl.font.color = {rgb:_safeColor(str[s])};
			break;
		case "background":{
			const fill = _safeColor(str[s]);
			if(fill && fill.toLowerCase() !== "ffffff")
				stl.fill = { fgColor:{ rgb:fill}};
			break;
		}	
		case "text-align":
			stl.alignment.horizontal = str[s];
			break;
		case "font-family":
			stl.font.name = str[s].replace(/'|,.*$/g, ""); // cut off fallback font
			break;
		case "font-size":
			stl.font.sz = str[s].replace("px", "")*0.75; //px to pt conversion
			break;
		case "font-style":
			stl.font.italic = str[s] == "italic";
			break;
		case "text-decoration":
			stl.font.underline = str[s] == "underline";
			break;
		case "font-weight":
			stl.font.bold = str[s] == "bold";
			break;
		case "vertical-align":
			stl.alignment.vertical = str[s] == "middle"?"center":str[s];
			break;
		case "wrap":
			stl.alignment.wrapText = str[s] == "wrap";
			break;
		case "borders":
			break;
		case "format":
			stl.format = getFormatSource(str[s], true) || "";
			break;
		case "border-right":
		case "border-bottom":
		case "border-left":
		case "border-top":
			stl.border[ style_names[s].split("-")[1] ] = getBorderStyles(str[s], true);
			break;
	}
}

function _getPdfCellStyle(stl, str, s){
	switch (style_names[s]) {
		case "color":
			stl.color = _safeColor(str[s]);
			break;
		case "background":
			stl.backgroundColor = _safeColor(str[s]);
			break;
		case "text-align":
			stl.textAlign = str[s];
			break;
		case "font-family":
			break;
		case "font-size":
			stl.fontSize = str[s].replace("px", "")*0.75;
			break;
		case "font-style":
			stl.italic = str[s] == "italic";
			break;
		case "text-decoration":
			stl.underline = str[s] == "underline";
			break;
		case "font-weight":
			stl.bold = str[s] == "bold";
			break;
		case "vertical-align":
			stl.vertAlign = str[s]; // not supported in PDFJS, so simulate with lineHeight and paddingTop
			break;
		case "wrap":
			stl.whiteSpace = (str[s] != "nowrap");
			break;
		case "borders":
			break;
		case "format":
			break;
		case "border-right":
		case "border-bottom":
		case "border-left":
		case "border-top":{
			const pos = style_names[s].split("-")[1];
			stl[`border${pos[0].toUpperCase() + pos.substring(1)}Color`] = getBorderStyles(str[s]);
			break;
		}
	}
}

function getBorderStyles(val, excel){
	const [color, type] = val.split(",");

	if(excel)
		return { color:{ rgb:_safeColor(color)}, style:type};
	else
		return _safeColor(color);
}

function _getDocStyle(css){
	if(!css) return {};
	var str = [];
	for(let i =0; i<style_names.length; i++)
		str.push(css[style_names[i]]||"");
	return _getCellStyle(str.join(";"), true);
}