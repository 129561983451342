import {Dialog} from "./common";
import {isRange, toSheetRange} from "../helpers/column_names";
import {getLastCharIndex} from "../mathematics/parser";

export const action = "add-range";

export class DialogBox extends Dialog{
	$show(box){
		const form = box.$$("form");

		form.clear();
		form.clearValidation();

		const elements = form.elements;

		elements.name.focus();

		let selectedCell = this.view.getSelectedRange();
		if(selectedCell)
			selectedCell = this.view.getActiveSheet()+"!"+selectedCell;

		form.setValues({
			range: selectedCell,
			scope: "$global"
		});

		this._updateData();

		this.view._rangeDialog = true;
		this.view.$handleSelection = (st, end, a, b)=>{
			form.elements.range.setValue(toSheetRange(a,b,this.view.getActiveSheet()));
			return false;
		};
	}
	$hide(){
		this.view.$handleSelection = null;
		this.view._rangeDialog = null;
	}
	saveClick(){
		const view = this.view;
		const form = this.$dialog.$$("form");
		const data = form.getValues();

		if(data.range){
			const rangeParts = data.range.split("!");

			if(rangeParts.length == 1)
				rangeParts.unshift( view.getActiveSheet() );

			data.range = rangeParts[0]+"!"+rangeParts[1].toUpperCase();
		}

		data.name = data.name.toUpperCase();

		form.setValues(data);

		if (form.validate()){
			const table = this.$dialog.$$("table");

			if(data.id){
				const oldData = table.getItem(data.id);

				view.ranges.remove(oldData.name, this._getScope(oldData));
				table.updateItem(data.id, data);
			}
			else
				table.add(data);

			view.ranges.add(
				data.name,
				data.range,
				this._getScope(data)
			);

			view.refresh();

			table.unselect();
			form.setValues({scope: "$global"});
		}
	}
	_getScope(data){
		return data.scope == "$global" ? true : data.scope;
	}
	removeRange(id){
		const view = this.view;
		view.confirm({
			text: webix.i18n.spreadsheet.labels["range-remove-confirm"]
		}).then(()=>{
			const table = this.$dialog.$$("table");
			const item = table.getItem(id);

			view.ranges.remove(item.name, this._getScope(item));

			view.refresh();
			table.remove(id);
		});
	}
	editRange(id){
		const form = this.$dialog.$$("form");
		const table = this.$dialog.$$("table");

		form.clearValidation();
		const item = webix.copy(table.getItem(id));

		form.setValues(item);
	}
	$init(){
		this.view.attachEvent("onSheetRename", (name, newName)=> this._updateData(name, newName));
		this.view.attachEvent("onSheetRemove", (name)=> this._updateData(name));
		this.view.attachEvent("onSheetAdd", ()=> this._updateData());

		const locale = webix.i18n.spreadsheet.labels;
		const theform = {
			padding:0,
			type: "clean",
			cols:[
				{ view: "ssheet-dialog-table", id: "table", borderless:true, columns:[
					{ id:"name", header:locale["range-name"], width: 120 },
					{ id:"range", header:locale["range-cells"], width: 150 },
					{ id:"scope", header:locale["range-scope"], width: 95, template: obj => {
						return obj.scope == "$global" ? locale["range-global"] : obj.scope;
					}},
					{ template:"<span class='webix_icon ssheet_ranges_edit wxi-pencil'></span>", width:44 },
					{ template:"<span class='webix_icon ssheet_ranges_edit wxi-trash'></span>", width:44 }
				],
				autowidth:true, height: 200, select:"row",
				on:{
					onBeforeSelect: id =>{
						return id == this.$dialog.$$("form").getValues().id;
					}
				},
				onClick:{
					"wxi-trash":(ev, id) => this.removeRange(id),
					"wxi-pencil":(ev, id) => this.editRange(id)
				}},
				{ width: 270, view: "form", id:"form",
					rules:{
						name: (_, obj)=> {
							const notUnique = this.$dialog.$$("table").find(item => {
								return item.name == obj.name && item.scope == obj.scope && item.id != obj.id;
							}, true);

							return !notUnique && !getLastCharIndex(obj.name);
						},
						range: isRange,
						scope: webix.rules.isNotEmpty
					},
					elementsConfig:{
						labelWidth: 95
					},
					elements:[
						{ view: "text", name:"name", label: locale["range-name"]},
						{ view: "text", name:"range", id:"range", label: locale["range-cells"], on:{
							onFocus:()=>{
								const value = this.$dialog.$$("range").getValue();
								const rangeParts = value.split("!");
								if(rangeParts.length == 2 && this.view.getActiveSheet() != rangeParts[0])
									this.view.showSheet(rangeParts[0]);
							}
						}},
						{ view: "richselect", name:"scope", id:"sheets", label: locale["range-scope"], suggest:{
							view: "ssheet-form-suggest",
							body:{
								data: []
							}
						}}
					]
				}
			]
		};

		return {
			view: "ssheet-dialog",
			move:true,
			head: webix.i18n.spreadsheet.labels["range-title"],
			autoheight: true,
			width: 800,
			position: "center",
			body: theform,
			on:{
				onSaveClick: () => this.saveClick(),
				onCancelClick: () => this.close()
			}
		};
	}
	_updateData(name, newName){
		if(!this.$dialog.isVisible())
			return;

		const ranges = this.view._mData.getRanges();
		const locale = webix.i18n.spreadsheet.labels;
		const table = this.$dialog.$$("table");
		const form = this.$dialog.$$("form");

		let editRange = table.getItem( form.getValues().id );
		if(editRange){
			let rangeName = editRange.name;

			if(name == editRange.scope)
				rangeName = newName ? newName+"!"+rangeName : null;
			else
				rangeName = editRange.scope+"!"+rangeName;

			editRange = rangeName ? ranges.get(rangeName) : null;
		}

		table.clearAll();

		const allRanges = ranges.serialize();
		const data = [];

		let selectedId;
		for(let i = 0; i < allRanges.length; i++){
			const id = i+1;
			let [name, range] = allRanges[i];
 
			if(editRange && ranges.get(name) == editRange)
				selectedId = id;

			const nameParts = name.split("!");

			data.push({
				scope: nameParts.length == 1 ? "$global" : nameParts[0],
				name: nameParts[1] || nameParts[0],
				range,
				id
			});
		}

		table.parse(data);

		form.setValues({id:selectedId}, true);
		if(selectedId)
			table.select(selectedId);

		const select = this.$dialog.$$("sheets");
		const selectList = select.getList();

		selectList.clearAll();

		const sheets = [{
			id: "$global", value: locale["range-global"]
		}];
		this.view._sheets.forEach(sheet => {
			sheets.push({id:sheet.name, value: sheet.name});
		});
		selectList.parse(sheets);

		if(name){
			const range = this.$dialog.$$("range");
			const rangeVal = range.getValue();

			//remove sheet
			newName = newName || this.view.getActiveSheet();

			if(rangeVal.indexOf(name+"!") == 0)
				range.setValue((newName)+"!"+rangeVal.split("!")[1]);

			if(select.getValue() == name)
				select.setValue(newName);
		}
	}
}