import {getTextSize} from "./styles";

export function selectRow(row, view, endRow){
	var columns = view.$$("cells").config.columns;
	var firstColumn = columns[0].id == "rowId" ? 1 : 0;

	var start = {
		row:row,
		column:columns[firstColumn].id
	};
	var last = {
		row: (endRow || row),
		column: columns[columns.length-1].id
	};

	if(start.column && last.column)
		_selectRange(start, last, view);
}

export function selectAll(view){
	var order = view.$$("cells").data.order;
	var columns = view.$$("cells").config.columns;
	var firstColumn = columns[0].id == "rowId" ? 1 : 0;

	var start = {
		row: order[0],
		column: columns[firstColumn].id
	};
	var last = {
		row: order[order.length-1],
		column: columns[columns.length-1].id
	};

	if(start.column && last.column)
		_selectRange(start, last, view);
}

export function selectColumn(column, view, endColumn){
	var order = view.$$("cells").data.order;
	var startRow = order[0];
	var lastRow = order[order.length-1];

	var start = {row: startRow, column: column};
	var last = {row: lastRow, column: (endColumn || column) };

	_selectRange(start, last, view);
}

export function isColSelected(column, view){
	const selectedArea = view.$$("cells").getSelectArea();
	if (selectedArea){
		if (column >= selectedArea.start.column && column <= selectedArea.end.column)
			return true;
	}
	return false;
}

export function isCellSelected(row, column, view){
	return isRowSelected(row, view) && isColSelected(column, view);
}

export function isRowSelected(row, view){
	const selectedArea = view.$$("cells").getSelectArea();
	if (selectedArea){
		if (row >= selectedArea.start.row && row <= selectedArea.end.row)
			return true;
	}
	return false;
}

export function selectColumns(column, view) {
	const selectedArea = view.$$("cells").getSelectArea();

	if (!selectedArea)
		selectColumn(column, view);
	else {
		let col1 = Math.min(selectedArea.start.column, selectedArea.end.column, column);
		let col2 = Math.max(selectedArea.start.column, selectedArea.end.column, column);
		selectColumn(col1, view, col2);
	}
}

function _selectRange(a, b, view){
	view.$$("cells").addSelectArea(a, b);
}


export function adjustColumn(column, view) {
	let width = 25;
	const columnWidth = view._table.getColumnConfig(column).width;

	view._table.eachRow(function(row) {
		const span = view._table.getSpan(row, column);
		if(span && span[2] > 1)
			return;

		const text = this.getText(row, column);
		const style = view.getStyle(row, column);
		const css = style ? style.id : "";

		if (text.replace(/<\/?[^>]+(>|$)/g, "")) {
			const size = getTextSize(view, text, css);

			if(view.getCellEditor(row, column))
				size.width += 20;
			if(view.getCellFilter(row, column))
				size.width += 20;

			if(style && style.props.wrap == "wrap" && size.width > columnWidth)
				size.width = columnWidth;
			if (size.width > width)
				width = size.width;
		}
	});
	view._table.setColumnWidth(column, width);
}

export function adjustRow(row, view) {
	let height = 25;

	view._table.eachColumn(function(column) {
		if(column != "rowId"){
			const text = this.getText(row, column);

			// if there is text wrap in cell we need to know width to get correct height
			let width = this.getColumnConfig(column).width;
			const span = view._table.getSpan(row, column);
			if(span)
				width = view._table.getSpanNode({row:span[0], column:span[1]}).offsetWidth;

			let style = view.getStyle(row, column);
			let css = style ? style.id : "";

			if (text.replace(/<\/?[^>]+(>|$)/g, "")) {
				let size = getTextSize(view, text, css, width);
				if (size.height > height) height = size.height;
			}
		}
	});
	view._table.setRowHeight(row, height);
}

export function highlightColRow(start, end, view){
	if(!view._table.config.header) return;

	const grid = view._table;

	const css = "webix_highlight";

	let sr = start.row, er = end.row, sc = start.column, ec = end.column;

	const columns = grid.config.columns;
	const firstColumn = columns[1].id;

	if(sc == "rowId"){
		if(sc == ec){
			start.column = sc = firstColumn;
			end.column = ec = columns[ columns.length-1 ].id;
		}
		else
			start.column = sc = firstColumn;
	}
	else if(ec == "rowId")
		end.column = ec = firstColumn;

	if(er<sr) [sr, er] = [er, sr];
	if(ec<sc) [sc, ec] = [ec, sc];

	grid.eachRow(function(row){
		if(row < sr || row > er)
			grid.removeCellCss(row, "rowId", css, true);
		else
			grid.addCellCss(row, "rowId", css, true);
	});
	webix.delay(() => grid.refresh());

	grid.eachColumn(function(col){
		const node = grid.getHeaderNode(col);
		if(col == "rowId" || !node) return;

		if(col < sc || col > ec)
			webix.html.removeCss(node, css);
		else
			webix.html.addCss(node, css, true);
	});
}